import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Rule } from '../data/rule';
import { FinancialRule } from '../data/financial-rule';
import { DOCUMENT } from '@angular/common';

export interface ModelType {
  countryName: string;
  type: string;
}

@Injectable()
export class DataSharingService {
  
  public dataForNextStep = {};
  public dialogType = '';
  public modelDetails = '';
  public currentFlow: string;
  public headPagination = new BehaviorSubject<any>({});
  public payeeType = new BehaviorSubject<any>({});
  public routeData = new BehaviorSubject<any>({});
  public individualReceiptData = new BehaviorSubject<any>({});
  public individualServiceRequestData = new BehaviorSubject<any>({});
  public immediateData = new BehaviorSubject<any>({});
  public printContent = new BehaviorSubject<any>({});
  dataFromFirstStep: any;
  dataFromSecondStep: any;
  dataFromThirdStep;
  dataForReceiptPage: any;
  accountSelection: any;
  public errorOccured = new BehaviorSubject<boolean>(false);
  public services = new BehaviorSubject<any>({});
  public idleTimeout = new BehaviorSubject<boolean>(false);
  public editIconClick = new Subject<void>();
  public editIconClicked = this.editIconClick.asObservable();
  public readOnlyMode = new BehaviorSubject<boolean>(false);
  public closeCurrentModule = new BehaviorSubject<any>({
    message: null,
    buttonOneText: null,
    buttonTwoText: null,
    buttonTwoDanger: false,
    routeBackTo: null,
    showModule: false,
  });
  public selectedApprover: Rule | FinancialRule = null;
  public errorMessage = new BehaviorSubject<any>({});
  public serviceResult = new BehaviorSubject<any>({});
  public limitResult = new BehaviorSubject<any>({});
  public limitReviewResult = new BehaviorSubject<any>({});
  public eapLimitMsg = new BehaviorSubject<any>({});

  public showBeneficiaryAddedToast = false;
  public userRequestedFeedback = new BehaviorSubject<boolean>(false);
  public changePasswordMessage = new BehaviorSubject<string>('');
  public forcedChangeUsernameNotification = new BehaviorSubject<string>('');
  public selectedFile = new BehaviorSubject<any>({});
  public currentModuleName: string;
  public flowOriginatorUrl: string;
  showMsgSubject = new BehaviorSubject<boolean>(false);
  bopcode = new BehaviorSubject<any>({});
  public maskFinancialFlows = new BehaviorSubject<boolean>(false);
  public otherAccountDetails: any;
  valueValidationSubject = new BehaviorSubject<boolean>(false);
  public pureSavePayload = new BehaviorSubject<any>({});
  private stepsSubject = new BehaviorSubject<any>([]);
  private commonRefId = new BehaviorSubject<any>([]);
  private successFileUploaded = new BehaviorSubject<any>([]);
  private activeStepSubject = new BehaviorSubject<any>('');
  private headerResponse = new BehaviorSubject<any>('');
  private disbursedMvp2Data = new BehaviorSubject<any>({});
  private headerTitleSubject = new BehaviorSubject<any>('');
  private notificationSubject = new BehaviorSubject<string>('');
  private libraCategoryvalue = new BehaviorSubject<string>('');
  private iscmaCountry = new BehaviorSubject<boolean>(false);
  private bulfileDataSubject = new BehaviorSubject<any>({});

  amountDealDetailsDebited: any;
  documentReference: any;
  referenceNumber: any;
  beneDetails: any;
  swiftCode: string;

  public headerEditDelete = new BehaviorSubject<boolean>(false);
  public txnType;
  public isHeaderDelete = new BehaviorSubject<boolean>(false);
  private formsDataSubject = new BehaviorSubject<any>([]);
  private mcontextSubject = new BehaviorSubject<any>({});
  public isprint = new BehaviorSubject<boolean>(false);
  constructor(@Inject(DOCUMENT) private document: Document) {}


  // setter section
  setSwiftCode(value){
    this.swiftCode = value;
  }
  setRequiredDoc(value) {
    this.valueValidationSubject.next(value);
  }
  setDocumentRef(docRef) {
    this.commonRefId.next(docRef);
  }
  setSuccessFullFileUploaded(filesKey) {
    this.successFileUploaded.next(filesKey);
  }
  setBopCode(resultBopcode) {
    this.bopcode.next(resultBopcode);
  }
  setlogOutMsg(flag: boolean) {
    this.showMsgSubject.next(flag);
  }
  setNotfication(text: string) {
    this.notificationSubject.next(text);
  }
  setLibraCategoryvalue(text: string) {
    this.libraCategoryvalue.next(text);
  }
  setIscmaCountry(data: boolean){
    this.iscmaCountry.next(data);
  }
  setmvp2DisbursedData(data: object) {
    this.disbursedMvp2Data.next(data);
  }
  setPaginationSteps(steps: string[]) {
    this.stepsSubject.next(steps);
  }
  setHeaderResponse(data: object) {
    this.headerResponse.next(data);
  }
  setPaginationActiveStep(activeStep: string): void {
    this.activeStepSubject.next(activeStep);
  }
  setHeadPagination(data: object): void {
    this.headPagination.next(data);
  }
  setErrorModal(data: boolean): void {
    this.document.defaultView.scrollTo(0, 0);
    this.errorOccured.next(data);
  }
  setIdleTimeout(data: boolean): void {
    this.idleTimeout.next(data);
  }
  setHeaderTitle(newValue: string): void {
    this.headerTitleSubject.next(newValue);
  }
  setPayeeType(data: object): void {
    this.payeeType.next(data);
  }
 
  setIndividualReceiptData(data: object): void {
    this.individualReceiptData.next(data);
  }
  setIndividualServiceRequestData(data: object): void {
    this.individualServiceRequestData.next(data);
  }
  setPrintData(data: object): void {
    this.printContent.next(data);
  }
  setRouteData(data: object): void {
    this.routeData.next(data);
  }
  setReadOnlyMode(data: boolean): void {
    this.readOnlyMode.next(data);
  }
  setCloseCurrentModule(data: object): void {
    this.closeCurrentModule.next(data);
  }
  setErrorMessage(data: string): void {
    this.errorMessage.next(data);
  }
  setServiceRequestResultData(data: object): void {
    this.serviceResult.next(data);
  }
  setChangeLimit(data: object): void {
    this.limitResult.next(data);
  }
  setChangeLimitReview(data: object): void {
    this.limitReviewResult.next(data);
  }
  setEapLimitApproveMsg(data:any): void {
    this.eapLimitMsg.next(data);
  }
  setUserRequestedFeedback(data: boolean): void {
    this.userRequestedFeedback.next(data);
  }
  setBulkFileData(data: object): void {
    this.bulfileDataSubject.next(data);
  }
  setChangePasswordMessage(text: string) {
    this.changePasswordMessage.next(text);
  }
  setSelectedFile(data: object): void {
    this.selectedFile.next(data);
  }
  setForcedChangeUsernameNotification(data: string) {
    this.forcedChangeUsernameNotification.next(data);
  }
  setMaskFinancialFlows(data: boolean): void {
    this.maskFinancialFlows.next(data);
  }
  setPureSavePayload(data: object): void {
    this.pureSavePayload.next(data);
  }

  setIsprint(data: boolean): void {
    return this.isprint.next(data);
  }
  getIsprint() {
    return this.isprint.asObservable();
  }

  // getter section
  getSwiftCode(){
   return this.swiftCode;
  }
  getRequiredDoc() {
    return this.valueValidationSubject.asObservable();
  }
  getDocumentRef() {
    return this.commonRefId.asObservable();
  }
  getSuccessFullFileUploaded() {
    return this.successFileUploaded.asObservable();
  }
  getBopCode() {
    return this.bopcode.asObservable();
  }

  getlogOutMsg() {
    return this.showMsgSubject.asObservable();
  }
  getNotfication() {
    return this.notificationSubject.asObservable();
  }
  getLibraCategoryvalue() {
    return this.libraCategoryvalue.asObservable();
  }
  getIscmaCountry(){
    return this.iscmaCountry.asObservable();
  }
  getmvp2DisbursedData() {
    return this.disbursedMvp2Data.asObservable();
  }
  getPaginationSteps() {
    return this.stepsSubject.asObservable();
  }

  getPaginationActiveStep() {
    return this.activeStepSubject.asObservable();
  }
  getHeaderResponse() {
    return this.headerResponse.asObservable();
  }
  getHeaderTitle() {
    return this.headerTitleSubject.asObservable();
  }
  getHeadPagination() {
    return this.headPagination.asObservable();
  }
  getErrorModal() {
    return this.errorOccured.asObservable();
  }
  getIdleTimeout() {
    return this.idleTimeout.asObservable();
  }
  getPayeeType() {
    return this.payeeType.asObservable();
  }
  

  getindividualReceiptData() {
    return this.individualReceiptData.asObservable();
  }
  getIndividualServiceRequestData() {
    return this.individualServiceRequestData.asObservable();
  }
  getPrintData() {
    return this.printContent.asObservable();
  }
  getRouteData() {
    return this.routeData.asObservable();
  }
  getReadOnlyMode() {
    return this.readOnlyMode.asObservable();
  }
  getCloseCurrentModule() {
    return this.closeCurrentModule.asObservable();
  }
  getErrorMessage() {
    return this.errorMessage.asObservable();
  }
  getServiceRequestResultData() {
    return this.serviceResult.asObservable();
  }
  getChangeLimit() {
    return this.limitResult.asObservable();
  }
  getChangeLimitReview() {
    return this.limitReviewResult.asObservable();
  }
  getEapLimitApproveMsg() {
    return this.eapLimitMsg.asObservable();
  }
  getUserRequestedFeedback() {
    return this.userRequestedFeedback.asObservable();
  }
  getForcedChangeUsernameNotification() {
    return this.forcedChangeUsernameNotification.asObservable();
  }

  resetStepData() {
    this.dataFromFirstStep = null;
    this.dataFromSecondStep = null;
    this.dataFromThirdStep = null;
    this.dataForReceiptPage = null;
    this.setHeaderTitle('');
  }

  setObject(data) {
    this.immediateData.next(data);
  }
  getObject() {
    return this.immediateData.asObservable();
  }
  setTxnType(value: string) {
    this.txnType = value;
  }
  getTxnType() {
    return this.txnType;
  }
  setPostService(attachedFunction) {
    this.services.next(attachedFunction);
  }
  getPostService() {
    return this.services.asObservable();
  }
  getBulkFileData() {
    return this.bulfileDataSubject.asObservable();
  }
  getChangePasswordMessage() {
    return this.changePasswordMessage.asObservable();
  }
  getSelectedFile() {
    return this.selectedFile.asObservable();
  }
  getMaskFinancialFlows() {
    return this.maskFinancialFlows.asObservable();
  }
  getPureSavePayload(){
    return this.pureSavePayload.asObservable();
  }

  setDocumentReference(data) {
    this.documentReference = data;
  }
  setSelectedAccount(data) {
    this.accountSelection = data;
  }
  setAmountDealDetails(data) {
    this.amountDealDetailsDebited = data;
  }
  setReferenceNumber(data) {
    this.referenceNumber = data;
  }
  setBeneDetails(data) {
    this.beneDetails = data;
  }

  // getter section
  getBeneDetails() {
    return this.beneDetails;
  }
  getReferenceNumber() {
    return this.referenceNumber;
  }
  getAmountDealDetails() {
    return this.amountDealDetailsDebited;
  }
  getSelectedAccount() {
    return this.accountSelection;
  }
  getDocumentReference() {
    return this.documentReference;
  }
  getMobileContext() {
    return this.mcontextSubject.asObservable();
  }
  
}
