import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { BankingMetadataService } from '../../../../core/banking-metadata/banking-metadata.service';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { AppConstantService } from '../../../../core/app-constant/app-constant.service';
import { PaymentUtilitiesService } from '../../../../core/payment-utilities/payment-utilities.service';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { UuidGeneratorService } from '../../../../core/UUID-generator/uuid-generator.service';
import { setOnceOffPayBeneDetailData } from '../../../../shared/store-utilities/actions/once-off-pay-beneficiary.action';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import { environment } from '../../../../../environments/environment';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import { CutOffTimeService } from '../../../../services/cut-off-time-service/cut-off-time.service';

@Component({
  selector: 'sbg-once-off-beneficiary-details',
  templateUrl: './once-off-beneficiary-details.component.html',
  styleUrls: ['./once-off-beneficiary-details.component.scss'],
})
export class OnceOffBeneficiaryDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('paymentDetailsForm')
  formRef: NgForm;
  destroyed$ = new Subject<boolean>();

  config = environment.config;

  

  availableLimit: any;
  withdrawalLimit: any;

  avilableDailyLimit: any;
  usedLimit: any;
  isNrtAvailable = false;

  localBank: any;
  localCurrency: any;

  bankLogo: any;
  accounts: any[] = [];
  banks: any[] = [];
  routeHistory: any[] = [];

  allCharges: any;
  mainHeading: any;
  wibCurrencyRestrict: any;
  currentFlow: any;
  countryCodes: any;

  validMobileNumber = true;
  incorrectMultiCurrencyError = false;
  incorrectMultiCurrencyMsg = '';
  invalidAccountNumber: any;
  isAmountExceeding: any;
  isAmountEntered: any;
  exceededMaxPaymentLimit: any;

  isHoliday: any;
  isValidBank: any;
  isAmountValid: any;
  fromAccCurrency: any;

  availableTransferLimit: any;
  paymentCurrency: any;

  editSelectedBranch: any;
  editReviewModel: any;
  showSchedulePayment = false;
  backFromReview = false;
  onceoffDataForConfirmationPage: any;
  isSmeMode: false;
  routeDetails: any;

  defaultModeOfCommunication = this.config.defaultModeOfCommunication;

  paymentModel: any = {
    networkTypes: [],
    paymentPriority: {},
    selectedRoute: {},
    currentFlow: '',
    choosenBank: '',
    currentDate: new Date(),
    showACHToRTGAmountError: false,
    invalidMobileNumber: false,
    isPaymentScheduled: false,
    selectedPaymentProof: this.defaultModeOfCommunication,
    paymentConfirmation: {},
  };

  isAllowedScheduledPayment = this.paymentUtilities.checkIfScheduledPaymentsIsAllowedForTransactionType('ONCE_OFF_BANK_BENEFICIARY');

  //  /"SMS", "Email", "None"
  paymentProofs = [
    { name: 'SMS', value: 'SMS', id: 1 },
    { name: 'Email', value: 'EMAIL', id: 2 },
    { name: 'None', value: 'NONE', id: 3 },
  ];

  approversAmountErrorMsg: any = 'The amount entered is not in range. Please enter an amount within the approval amount range.';
  searchBankCtrl = '';
  searchBranchCtrl = '';
  screenSize: string;
  taxCodes: any;
  showIMTTMessage: boolean;
  taxCodeMessage: string;
  showPaymentPriorityTz = false;
  private keyToRemovePaymentConfirmation = ['countryCode', 'address', 'email', 'confirmationType', 'mobileNumber'];

  constructor(
    private dataSharingService: DataSharingService,
    private bankingMetadataService: BankingMetadataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cutOffTimeService: CutOffTimeService,
    private accountService: AccountService,
    private accountsIterator: AccountsIteratorService,
    private appConstant: AppConstantService,
    private routeState: RouterStateService,
    private paymentUtilities: PaymentUtilitiesService,
    private transactionService: TransactionService,
    private uuidGeneratorService: UuidGeneratorService,
    private deviceDetectorService: DeviceDetectorService,
    private store: Store<any>
  ) {
    this.paymentModel.currentFlow = 'once-off-pay';

    this.routeHistory = this.routeState.getHistory();
    const prevUrl = _.last(this.routeHistory);
    store.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });

    if (prevUrl.search('review') !== -1) {
      this.backFromReview = true;
      this.isAmountEntered = true;

      store
        .select('appReducer', 'onceOffPayBeneficiaryReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          const reviewModel: any = _.cloneDeep(stateData.onceOffPayBeneDetailData);
          this.paymentModel = reviewModel.paymentModel;

          if (this.paymentModel.selectedBranch && this.paymentModel.selectedBranch.name) {
            this.editSelectedBranch = this.paymentModel.selectedBranch.name;
          }

          this.showSchedulePayment = true;
        });
    } else if (prevUrl.search('scheduleTransaction/receipt') !== -1) {
      store
        .select('appReducer', 'editTxnScheduleReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          this.editReviewModel = _.cloneDeep(stateData.editOnceOffBankBeneficiary);
          this.paymentModel = _.cloneDeep(this.paymentModel);

          this.paymentModel.editReviewModel = this.editReviewModel;
          this.paymentModel.isFlowEditSchedule = this.editReviewModel.isFlowEditSchedule;
          this.paymentModel.isPaymentScheduled = this.editReviewModel.isFlowEditSchedule;
          this.paymentModel.amount = this.editReviewModel.scheduledAmount.amount;
          this.paymentModel.currency = this.editReviewModel.scheduledAmount.currency;

          this.paymentModel.benefName = this.editReviewModel.name;
          this.paymentModel.selectedPaymentProof = this.editReviewModel.selectedRadio || 'NONE';

          this.paymentModel.accountNumber = this.editReviewModel.accountNumber;
          this.paymentModel.yourReference = this.editReviewModel.customerReference;
          this.paymentModel.theirReference = this.editReviewModel.recipientReference;

          this.paymentModel.currentDate = this.editReviewModel.nextPaymentDate;
          this.paymentModel.type = this.editReviewModel.type;
          this.paymentModel.networkType = this.editReviewModel.networkType;
          this.paymentModel.selectedBranch = this.editReviewModel.route.branch;
          this.paymentModel.paymentConfirmation = this.editReviewModel.paymentConfirmation || {};

          if (this.editReviewModel.route && this.editReviewModel.route.branch && this.editReviewModel.route.branch.name) {
            this.editSelectedBranch = this.editReviewModel.route.branch.name;
          }

          this.paymentModel.currentFlow = 'edit-once-off';
          this.showSchedulePayment = true;
        });
    }

    this.mainHeading = this.paymentModel.currentFlow === 'once-off-pay' ? 'Pay new recipient' : `Edit ${this.paymentModel.benefName} schedule`;

    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;

      this.dataSharingService.setHeadPagination({
        mainTitle: this.mainHeading,
        subTitle: 'Details',
        showBack: this.screenSize === 'small',
        showClose: true,
        steps: true,
        module: 'onceOffPaymentDetails',
        showBackFn: this.goBackToMenu.bind(this),
      });
    });
  }

  ngOnInit() {
    this.localBank = this.config.localBank;
    this.localCurrency = this.config.localCurrency;
    this.bankLogo = this.appConstant.BankLogo;
    this.accounts = this.accountsIterator.getPaymentFromAccounts(this.config.payments.payBeneficiary.beneficiaryPaymentCurrency);
    this.paymentModel.accounts = _.cloneDeep(this.accounts);

    if (this.config.payments.payBeneficiary.checkPublicHolidays || this.config.checkMultiCurrencyHoliday) {
      this.fetchPublicHolidaysList();
    }
    this.getAmountWithdrawalLimit();

    this.getBanksList();
    this.setDataFn();
    this.getCountryCodes();

    if (this.config.intermediatedMoneyTransferTax) {
      this.fetchMoneyTransferTaxCodes();
    }
  }

  goBackToMenu() {
    return this.router.navigate(['/menu/payments']);
  }

  getScheduleValues(values) {
    if (values) {
      this.paymentModel.isPaymentScheduled = values.isPaymentScheduled;
      this.paymentModel.oftenSchedule = values.oftenSchedule;
      this.paymentModel.numberOfPayments = values.numberOfPayments;
      this.paymentModel.currentDate = values.scheduleDate;
      this.paymentModel.module = values.module || '';

      if (this.paymentModel.currentFlow === 'edit-once-off') {
        this.editReviewModel.nextPaymentDate = values.scheduleDate;
      }

      if (this.formRef.form.controls.amount) {
        (this.formRef.form.controls.amount as FormGroup).setErrors(null);
      }
    }
  }

  getAmountWithdrawalLimit() {
    this.withdrawalLimit = this.config.showMonthlyTransactionLimit
      ? this.accountService.cardProfile.monthlyWithdrawalLimit
      : this.accountService.cardProfile.dailyWithdrawalLimit;

    this.usedLimit = this.config.showMonthlyTransactionLimit
      ? this.accountService.cardProfile.monthlyUsedLimit
      : this.accountService.cardProfile.usedEAPLimit;

    if (this.withdrawalLimit && this.usedLimit) {
      this.availableLimit = this.withdrawalLimit.amount - this.usedLimit.amount;
      this.availableLimit = parseFloat(parseFloat(this.availableLimit).toFixed(2));
    }
  }

  fetchPublicHolidaysList() {
    this.bankingMetadataService.publicHolidaysList().subscribe((response) => {
      if (response.length) {
        this.paymentModel.publicHolidays = response;
      }
    });
  }

  setDataFn() {
    if (!this.paymentModel.selectedFromAccount) {
      this.paymentModel.selectedFromAccount = this.accounts[0];

      if (this.editReviewModel && this.editReviewModel.fromAccount && this.editReviewModel.fromAccount.number) {
        const accNo = this.editReviewModel.fromAccount.number;

        if (accNo) {
          const findAccs = this.accounts.filter((value) => {
            return value.number === accNo;
          });
          this.paymentModel.selectedFromAccount = findAccs[0];
        }
      }
    } else if (
      (this.paymentModel && this.paymentModel.selectedFromAccount && this.paymentModel.selectedFromAccount.number) ||
      (this.editReviewModel && this.editReviewModel.fromAccount && this.editReviewModel.fromAccount.number)
    ) {
      let accNo;
      if (this.editReviewModel) {
        accNo = this.editReviewModel.fromAccount.number;
      } else {
        accNo = this.paymentModel.selectedFromAccount.number;
      }

      if (accNo) {
        const findAccs = this.accounts.filter((value) => {
          return value.number === accNo;
        });
        this.paymentModel.selectedFromAccount = findAccs[0];
      }
    }
    if (parseFloat(this.paymentModel.amount)) {
      this.amountValueChanged(this.paymentModel.amount);
    }

    this.availableTransferLimit = this.paymentModel.selectedFromAccount.availableBalance.amount;

    this.paymentCurrency = this.paymentModel.selectedFromAccount.availableBalance.currency;
    this.paymentModel.currencyForSchedule = this.paymentCurrency;
    if (
      this.config.countryName === 'Tanzania' &&
      !this.paymentModel.isWithinBank &&
      this.paymentModel.selectedFromAccount.accountCurrency.currencyCode !== 'TZS'
    ) {
      this.showPaymentPriorityTz = true;
    }
  }

  public branchDropDownChanged(data: any) {
    this.paymentModel.selectedBranch = data;
    // this.fetchRoutesByBranch(data);
    if (this.config.allowNrtCutOffTime) {
      this.fetchRtgsCutOffTimings(data);
    } else {
      this.fetchRoutesByBranch(data);
    }
    this.paymentModel.beneficiaryAddress = null;
  }
  fetchRtgsCutOffTimings(data) {
    this.bankingMetadataService.getCutOffTimes().subscribe((response: any) => {
      const cutOffTimeInfo = this.cutOffTimeService.checkCutOffTime(response.body.setting);
      this.isNrtAvailable = cutOffTimeInfo.isNrtAvailable;
      this.fetchRoutesByBranch(data);
    });
  }

  public accountValueChanged(newValue) {
    this.paymentModel.selectedFromAccount = newValue;
    if (this.config.localCurrency === 'GHS') {
      if (
        this.paymentModel.choosenBank === this.config.localBank &&
        this.paymentModel.selectedFromAccount.accountCurrency.currency !== this.config.localCurrency
      ) {
        this.wibCurrencyRestrict = true;
      } else {
        this.wibCurrencyRestrict = false;
      }
    } else if (this.config.localCurrency === 'BWP') {
      if (
        this.paymentModel.choosenBank !== this.config.localBank &&
        this.paymentModel.selectedFromAccount.accountCurrency.currency !== this.config.localCurrency
      ) {
        this.wibCurrencyRestrict = true;
      } else {
        this.wibCurrencyRestrict = false;
      }
    }
    if (this.wibCurrencyRestrict) {
      this.formRef.form.controls.bankName.setErrors({
        errorMsg: true,
      });
    } else {
      this.formRef.form.controls.bankName.setErrors(null);
    }

    this.availableTransferLimit = newValue.availableBalance.amount;
    this.paymentCurrency = newValue.availableBalance.currency;
    this.paymentModel.currencyForSchedule = this.paymentCurrency;
    this.paymentModel.amount = '';
    if (
      this.config.countryName === 'Tanzania' &&
      !this.paymentModel.isWithinBank &&
      this.paymentModel.selectedFromAccount.accountCurrency.currencyCode !== 'TZS'
    ) {
      this.showPaymentPriorityTz = true;
      if (this.paymentModel.routeObjectRTG) {
        this.paymentModel.selectedRoute.networkType = this.paymentModel.routeObjectRTG.networkType;
        this.paymentModel.selectedRoute.routingNumber = this.paymentModel.routeObjectRTG.routingNumber;
      } else {
        this.paymentModel.selectedRoute.networkType = 'RTG';
      }
    } else {
      this.showPaymentPriorityTz = false;
    }
  }

  amountValueChanged(newValue: any) {
    if (this.paymentModel.selectedFromAccount) {
      this.isAmountExceeding = false;

      this.paymentModel.calculatedCharge = {
        appliedCharge: 0,
      };

      this.paymentModel.totalPayableAmount = parseFloat(newValue) + this.paymentModel.calculatedCharge.appliedCharge;
      this.paymentModel.totalPayableAmount = parseFloat(parseFloat(this.paymentModel.totalPayableAmount).toFixed(2));

      this.paymentModel.amountForSchedule = this.paymentModel.amount;

      if (this.paymentModel.networkTypes && this.paymentModel.networkTypes.length && this.paymentModel.networkTypes[0].networkType !== 'WIB') {
        this.paymentModel = this.paymentUtilities.switchNetworkType(this.paymentModel, newValue, 0);
      }
    }
  }

  amountFocusOut() {
    if (parseFloat(this.paymentModel.totalPayableAmount)) {
      this.isAmountEntered = true;
    }
  }

  fetchMoneyTransferTaxCodes() {
    this.bankingMetadataService.getMoneyTransferTaxCode().subscribe((response) => {
      this.taxCodes = response.taxCodes;
      this.paymentModel.moneyTransferTaxCode = this.taxCodes[0];
      this.moneyTransferTaxCodeChanged(response.taxCodes[0]);
      if (this.isIMTTApplicable(this.paymentModel.selectedFromAccount)) {
        if (!this.paymentModel.moneyTransferTaxCode) {
          this.moneyTransferTaxCodeChanged(response.taxCodes[0]);
        }
      } else {
        delete this.paymentModel.moneyTransferTaxCode;
      }
    });
  }

  isIMTTApplicable(account: any) {
    this.showIMTTMessage = this.config.localCurrency === account.accountCurrency.currency;
    return this.showIMTTMessage;
  }

  moneyTransferTaxCodeChanged(taxCode: any) {
    switch (taxCode.key) {
      case '001':
        this.taxCodeMessage = 'A ' + this.config.intermediatedMoneyTransferTax + '% IMTT charge will be levied on the transaction amount.';
        break;
      default:
        this.taxCodeMessage =
          'Please note that you have selected not to pay IMTT on this transaction. ' +
          'Tax evasion is a crime and you are liable for any fines and penalties that may arise from this choice.';
        break;
    }
    this.paymentModel.moneyTransferTaxCode = taxCode;
  }

  bankValueChanged(data: any, isEditBranchRemove) {
    if (isEditBranchRemove) {
      this.editSelectedBranch = null;
    }
    this.paymentModel.detailsOperatorName = data;
    this.paymentModel.onceOffBank = data;
    this.paymentModel.choosenBank = data.name || data.value;

    if (this.config.localCurrency === 'GHS') {
      if (
        this.paymentModel.choosenBank === this.config.localBank &&
        this.paymentModel.selectedFromAccount.accountCurrency.currency !== this.config.localCurrency
      ) {
        this.wibCurrencyRestrict = true;
      } else {
        this.wibCurrencyRestrict = false;
      }
    } else if (this.config.localCurrency === 'BWP') {
      if (
        this.paymentModel.choosenBank !== this.config.localBank &&
        this.paymentModel.selectedFromAccount.accountCurrency.currency !== this.config.localCurrency
      ) {
        this.wibCurrencyRestrict = true;
      } else {
        this.wibCurrencyRestrict = false;
      }
    }

    if (this.wibCurrencyRestrict) {
      this.formRef.form.controls.bankName.setErrors({
        errorMsg: true,
      });
    }

    this.amountValueChanged(this.paymentModel.amount);

    if (this.config.recipientsManagement.branchDropdown) {
      this.paymentModel.selectedBranch = null;
      this.getBankBranches();
    }
  }

  fetchRoutesByBranch(data: any) {
    const branchList = [];
    branchList.push(data);
    this.bankingMetadataService.getBranchRoutes(branchList).subscribe((res) => {
      this.paymentModel.isWithinBank = false;
      const response = res.body;
      this.routeDetails = response.branches[0].routes[0];
      let objNRT;
      let intRouteObjNRT;
      let indexOfCMAType;
      const routeResponse = response.branches;

      if (routeResponse.length) {
        this.paymentModel.networkTypes = routeResponse[0].routes;
      }

      if (routeResponse.length) {
        indexOfCMAType = -1;
        this.paymentModel.networkTypes = _.map(this.paymentModel.networkTypes, (intRouteObj: any, intRouteInd: any) => {
          objNRT = _.cloneDeep(intRouteObj);

          if (intRouteObj.networkType === 'ACH') {
            intRouteObj = {
              ...intRouteObj,
              ...{
                countryList: true,
                name: 'Normal',
                description: 'Payment will be processed in 1-3 business days',
              },
            };

            intRouteObjNRT = {
              ...objNRT,
              ...{
                countryList: true,
                networkType: 'NRT',
                name: 'NRT',
                description: 'Payment will be processed near real-time (premium charge)',
              },
            };

            this.paymentModel.routeObjectACH = intRouteObj;
          } else if (intRouteObj.networkType === 'RTG') {
            intRouteObj = {
              ...intRouteObj,
              ...{
                countryList: !this.config.zimToggle,
                name: 'Express',
                description: 'Same day payment(extra charge)',
              },
            };
            this.paymentModel.routeObjectRTG = intRouteObj;
          } else if (intRouteObj.networkType === 'WIB') {
            this.paymentModel.isWithinBank = true;
            this.paymentModel.selectedRoute.networkType = intRouteObj.networkType;
            this.paymentModel.selectedRoute.routingNumber = intRouteObj.routingNumber;
          } else {
            indexOfCMAType = intRouteInd;
          }
          return intRouteObj;
        });

        if (this.isNrtAvailable && this.paymentModel.routeObjectACH) {
          this.paymentModel.routeObjectNRT = intRouteObjNRT;
          this.paymentModel.networkTypes.push(this.paymentModel.routeObjectNRT);
        }

        if (indexOfCMAType >= 0) {
          this.paymentModel.networkTypes.splice(indexOfCMAType, 1);
        }

        if (this.paymentModel.isWithinBank) {
          this.showPaymentPriorityTz = false;
        }

        if (!this.paymentModel.isWithinBank) {
          if (!this.paymentModel.selectedRoute) {
            this.paymentModel = {
              ...this.paymentModel,
              selectedRoute: {},
            };
          }

          if (this.paymentModel.routeObjectACH) {
            this.paymentModel.selectedRoute = this.paymentModel.routeObjectACH || this.paymentModel.routeObjectRTG;
          } else if (!this.paymentModel.routeObjectACH && this.paymentModel.routeObjectRTG) {
            this.paymentModel.selectedRoute = this.paymentModel.routeObjectRTG;
          }

          if (this.localCurrency === 'USD') {
            this.showPaymentPriorityTz = true;
            this.paymentModel.selectedRoute = this.paymentModel.routeObjectRTG;
          }

          this.paymentModel.paymentPriority = this.paymentModel.selectedRoute;
          if (this.showPaymentPriorityTz && this.paymentModel.routeObjectRTG) {
            this.paymentModel.selectedRoute.networkType = this.paymentModel.routeObjectRTG.networkType;
            this.paymentModel.selectedRoute.routingNumber = this.paymentModel.routeObjectRTG.routingNumber;
          } else if (this.showPaymentPriorityTz && !this.paymentModel.routeObjectRTG) {
            this.paymentModel.selectedRoute.networkType = 'RTG';
          }
          // sept 16 bug fix for zim otp issue uncommenting for prod fix

          /* if (this.paymentModel.routeObjectACH) {
            this.paymentModel.selectedRoute = this.paymentModel.routeObjectACH;
          }
          if (this.paymentModel.routeObjectRTG) {
            this.paymentModel.selectedRoute = {
              networkType: this.paymentModel.routeObjectRTG.networkType,
              routingNumber: this.paymentModel.routeObjectRTG.routingNumber,
            };
          } */
        }

        this.amountValueChanged(this.paymentModel.amount);
      }
    });
  }

  paymentPriorityChanged(data) {
    this.paymentModel.selectedRoute = data;
    this.paymentModel.paymentPriority = data;
    this.paymentModel.beneficiaryAddress = null;
    this.amountValueChanged(this.paymentModel.amount);
  }

  checkValidity() {
    return this.isValidBank && !this.paymentModel.showACHToRTGAmountError;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAccountHolderName(bankBranchCode) {
    let route: any;
    let customerReference: any;
    let recipientReference: any;
    let customName: any;
    let bankName: any;
    let accountNumber: any;

    if (this.paymentModel.onceOffBank) {
      route = {
        branch: {
          address: this.paymentModel.selectedBranch.address,
          bank: this.paymentModel.onceOffBank,
          code: this.paymentModel.selectedBranch.code,
          name: this.paymentModel.selectedBranch.name,
        },
        networkType: this.paymentModel.networkTypes.length <= 1 ?  this.routeDetails.networkType : this.paymentModel.selectedRoute.networkType,
        routingNumber: this.paymentModel.networkTypes.length <= 1 ? this.routeDetails.routingNumber : this.paymentModel.selectedRoute.routingNumber,
      };
      customerReference = this.paymentModel.yourReference;
      recipientReference = this.paymentModel.theirReference;
      customName = this.paymentModel.benefName;
      accountNumber = this.paymentModel.accountNumber;
    } else {
      accountNumber = this.paymentModel.accountNumber;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      bankName = this.paymentModel.detailsBankName.name;
      customerReference = this.paymentModel.myRef;
      recipientReference = this.paymentModel.theirRef;
      customName = this.paymentModel.beneficiaryName;
    }
    const paymentConfirmationData = _.cloneDeep(this.paymentModel.paymentConfirmation);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const paymentConfirmationPayloadData = this.paymentUtilities.removeObjKey(paymentConfirmationData, this.keyToRemovePaymentConfirmation);
    const accHolderFullNamePayload = {
      beneficiaries: [
        {
          accountNumber,
          route,
          favourite: false,
          customerReference,
          recipientReference,
          name: customName,
          paymentConfirmation: {
            notifications: this.paymentModel.paymentConfirmation.notifications,
          },
          address: null,
        },
      ],
    };

    this.transactionService.getAccountHolderNameService(accHolderFullNamePayload).subscribe((response) => {
      if (response) {
        const res: any = _.cloneDeep(response);

        this.paymentModel.isAccountHolderNameFailed = false;
        this.paymentModel.accHolderName = res.fullName;
        this.paymentModel.keyValueMetadata = res.keyValueMetadata;
        this.paymentModel.toAccountCurrency = res.currencyCode;

        if (!this.config.payments.payBeneficiary.checkCrossCurrencyPayments) {
          this.navigateOnceoffToConfirmation(true);
        } else {
          if (this.localCurrency === 'SZL' && this.paymentModel.toAccountCurrency !== this.localCurrency) {
            this.incorrectMultiCurrencyError = true;
            this.incorrectMultiCurrencyMsg = 'Please note that cross currency payments are not allowed.';
          } else if (
            this.localCurrency === 'USD' &&
            this.fromAccCurrency !== this.localCurrency &&
            this.paymentModel.toAccountCurrency !== this.localCurrency &&
            this.fromAccCurrency !== this.paymentModel.toAccountCurrency
          ) {
            this.incorrectMultiCurrencyError = true;
            this.incorrectMultiCurrencyMsg = 'Please note that cross foreign currency payments are not allowed.';
          }
        }

        if (!this.incorrectMultiCurrencyError) {
          if (this.isHoliday && this.config.allowRtgsWeekend && this.config.checkMultiCurrencyHoliday) {
            if (this.fromAccCurrency !== this.localCurrency || this.paymentModel.toAccountCurrency !== this.localCurrency) {
              this.dataSharingService.setCloseCurrentModule({
                message: {
                  line1: '<p>Please note that cross foreign currency</p>' + '<p>payments are only allowed on a</p>' + '<p>business day</p>',
                },
                buttonOneText: 'OK',
                routeBackTo: null,
                showModule: true,
              });
            } else {
              if (this.checkValidity()) {
                this.navigateOnceoffToConfirmation(false);
              }
            }
          } else {
            if (this.checkValidity()) {
              this.navigateOnceoffToConfirmation(false);
            }
          }
        }
      }
    });
  }

  goToReview() {
    if (!this.wibCurrencyRestrict) {
      this.isValidBank = true;

      this.incorrectMultiCurrencyError = false;
      this.incorrectMultiCurrencyMsg = null;

      this.fromAccCurrency = this.paymentModel.selectedFromAccount.availableBalance.currency;

      if (
        this.paymentCurrency !== this.localCurrency &&
        this.paymentModel.choosenBank !== this.localBank &&
        this.config.payments.payBeneficiary.beneficiaryPaymentCurrency === 'local'
      ) {
        this.isValidBank = false;
      }

      if (this.config.payments.payBeneficiary.checkPublicHolidays || this.config.checkMultiCurrencyHoliday) {
        this.isHoliday = this.paymentUtilities.checkPublicHoliday(this.paymentModel.publicHolidays, this.paymentModel.currentDate);
      }

      if (this.config.payments.payBeneficiary.checkCrossCurrencyPayments) {
        if (this.paymentModel.onceOffBank.name !== this.config.localBank) {
          if (this.fromAccCurrency !== this.config.localCurrency) {
            this.incorrectMultiCurrencyError = true;
            this.incorrectMultiCurrencyMsg =
              'Please note that' +
              'foreign currency payments are not allowed for outside Bank. ' +
              'Please make a payment from a local currency account.';
          } else {
            if (
              this.isHoliday &&
              this.paymentModel.paymentPriority.networkType === 'RTG' &&
              this.paymentModel.beneficiaryAddress &&
              !this.config.allowRtgsWeekend
            ) {
              const lineOneText = '<p>You cannot make express payments</p><p>on weekends and public holidays.</p>';
              const lineTwoText = '<p>Please select the normal payment</p><p>priority option to continue</p>';

              this.dataSharingService.setCloseCurrentModule({
                message: {
                  line1: lineOneText,
                  line2: lineTwoText,
                },
                buttonOneText: 'OK',
                routeBackTo: null,
                showModule: true,
              });
            } else if (this.checkValidity()) {
              this.navigateOnceoffToConfirmation(false);
            }
          }
        } else {
          this.getAccountHolderName(this.paymentModel.selectedBranch);
        }
      } else if (
        this.isHoliday &&
        this.paymentModel.onceOffBank.name !== this.config.localBank &&
        this.paymentModel.beneficiaryAddress &&
        !this.config.allowRtgsWeekend
      ) {
        this.dataSharingService.setCloseCurrentModule({
          message: {
            line1: '<p>You cannot make express payments</p>' + '<p>on weekends and public holidays.</p>',
            line2: '<p>Please select the normal payment</p>' + '<p>priority option to continue</p>',
          },
          buttonOneText: 'OK',
          routeBackTo: null,
          showModule: true,
        });
      } else if (this.isHoliday && this.config.allowRtgsWeekend && this.config.checkMultiCurrencyHoliday) {
        if (this.fromAccCurrency !== this.localCurrency) {
          if (this.localCurrency === 'ZWL' && this.fromAccCurrency === 'USD') {
            this.navigateOnceoffToConfirmation(false);
          } else {
            this.dataSharingService.setCloseCurrentModule({
              message: {
                line1: '<p>Please note that cross foreign currency</p>' + '<p>payments are only allowed on a</p>' + '<p>business day</p>',
              },
              buttonOneText: 'OK',
              routeBackTo: null,
              showModule: true,
            });
          }
        } else {
          this.navigateOnceoffToConfirmation(false);
        }
      } else {
        if (!this.config.recipientsManagement.branchDropdown) {
          this.bankingMetadataService.getBankBranches(this.paymentModel.onceOffBank, 'RTG').subscribe((response) => {
            this.paymentModel.selectedBranch = response.body.banks[0].branches[0];
          });

          if (this.config.localBank === this.paymentModel.onceOffBank.name.toUpperCase() && !this.config.fetchNameForInsideBank) {
            this.navigateOnceoffToConfirmation(false);
          } else {
            this.getAccountHolderName(this.paymentModel.selectedBranch);
          }
        } else if (this.config.payments.payBeneficiary.payLoanAccount && this.config.localBank === this.paymentModel.onceOffBank.name.toUpperCase()) {
          this.getAccountHolderName(this.paymentModel.selectedBranch);
        } else {
          this.navigateOnceoffToConfirmation(false);
        }
      }
    }
  }

  navigateOnceoffToConfirmation(isOutsideBank) {
    const paymentConfirmationData = _.cloneDeep(this.paymentModel.paymentConfirmation);
    const paymentConfirmationPayloadData = this.paymentUtilities.removeObjKey(paymentConfirmationData, this.keyToRemovePaymentConfirmation);
    if (this.config.countryName === 'Tanzania') {
      this.onceoffDataForConfirmationPage = {
        paymentModel: this.paymentModel,
        currentFlow: this.paymentModel.currentFlow,
        account: this.paymentModel.selectedFromAccount,
        transactions: {
          onceOffPayments: [
            {
              transactionId: this.uuidGeneratorService.getUUID(),
              amount: {
                amount: this.paymentModel.amount,
                currency: this.paymentCurrency,
              },
              futureDatedInstruction: {
                fromDate: this.paymentModel.currentDate,
                repeatInterval: 'SINGLE',
                repeatNumber: 1,
              },
              beneficiary: {
                name: this.paymentModel.benefName,
                accountNumber: this.paymentModel.accountNumber.replace(/[^a-zA-Z0-9]*/g, ''),
                accountCurrency: this.paymentModel.toAccountCurrency,
                route: {
                  branch: this.paymentModel.selectedBranch,
                  networkType: this.paymentModel.selectedRoute.networkType,
                  routingNumber: this.paymentModel.selectedRoute.routingNumber,
                },
                favourite: false,
                customerReference: this.paymentModel.yourReference.replace(/^0+/, ''),
                recipientReference: this.paymentModel.theirReference.replace(/^0+/, ''),
                paymentConfirmation: paymentConfirmationPayloadData || {},
              },
            },
          ],
        },
      };
    } else {
      this.onceoffDataForConfirmationPage = {
        paymentModel: this.paymentModel,
        currentFlow: this.paymentModel.currentFlow,
        account: this.paymentModel.selectedFromAccount,
        transactions: {
          onceOffPayments: [
            {
              transactionId: this.uuidGeneratorService.getUUID(),
              amount: {
                amount: this.paymentModel.amount,
                currency: this.paymentCurrency,
              },
              futureDatedInstruction: {
                fromDate: this.paymentModel.currentDate,
                repeatInterval: 'SINGLE',
                repeatNumber: 1,
              },
              beneficiary: {
                name: this.paymentModel.benefName,
                accountNumber: this.paymentModel.accountNumber.replace(/[^0-9]*/g, ''),
                accountCurrency: this.paymentModel.toAccountCurrency,
                route: {
                  branch: this.paymentModel.selectedBranch,
                  networkType: this.paymentModel.networkTypes.length <= 1 ?  this.routeDetails.networkType : this.paymentModel.selectedRoute.networkType,
                  routingNumber: this.paymentModel.networkTypes.length <= 1 ?  this.routeDetails.routingNumber : this.paymentModel.selectedRoute.routingNumber,
                },
                favourite: false,
                customerReference: this.paymentModel.yourReference.replace(/^0+/, ''),
                recipientReference: this.paymentModel.theirReference.replace(/^0+/, ''),
                paymentConfirmation: paymentConfirmationPayloadData || {},
              },
            },
          ],
        },
      };
    }

    if (this.config.countryName === 'Zimbabwe' && this.isIMTTApplicable(this.paymentModel.selectedFromAccount)) {
      this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].moneyTransferTaxCode = this.paymentModel.moneyTransferTaxCode;
    }

    if (this.dataSharingService.selectedApprover && this.isSmeMode) {
      this.onceoffDataForConfirmationPage.financialRule = this.dataSharingService.selectedApprover;
    }

    const tempObj = {
      ...this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary.route.branch,
      bank: this.paymentModel.onceOffBank,
    };

    this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary.route = {
      ...this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary.route,
      branch: tempObj,
    };

    if (this.config.recipientsManagement.branchDropdown) {
      this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary = {
        ...this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary,
        address: {
          addressLine1: this.paymentModel.beneficiaryAddress,
        },
      };
    }

    if (isOutsideBank && !this.config.payments.payBeneficiary.payLoanAccount) {
      this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary = {
        ...this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary,
        accountHolderFullName: this.paymentModel.accHolderName,
      };
    }

    if (isOutsideBank && this.config.payments.payBeneficiary.payLoanAccount) {
      this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary = {
        ...this.onceoffDataForConfirmationPage.transactions.onceOffPayments[0].beneficiary,
        keyValueMetadata: this.paymentModel.keyValueMetadata,
      };
    }
    const exchangeRateObject = {
      payerCurrency: this.paymentCurrency,
      payeeCurrency: this.config.localCurrency,
    };
    this.onceoffDataForConfirmationPage.exchangeRateObject = exchangeRateObject;
    this.store.dispatch(
      setOnceOffPayBeneDetailData({
        onceOffPayBeneDetailData: this.onceoffDataForConfirmationPage,
      })
    );
    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }

  goBack() {
    if (this.paymentModel.currentFlow === 'edit-once-off') {
      return this.router.navigate(['scheduleTransaction/receipt']);
    } else {
      return this.router.navigate(['/payments/beneficiary/list']);
    }
  }

  getNotifyValues(values) {
    if (values) {
      this.paymentModel.selectedPaymentProof = values.value.selectedPaymentProof;
      this.paymentModel.paymentConfirmation = values.value.paymentConfirmation;
      this.paymentModel.showMultiNotificationEmail = values.value.showMultiNotificationEmail;
      this.paymentModel.showMultiNotificationSms = values.value.showMultiNotificationSms;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  
  restrictZero(e) {
    if (e.target.value.length) {
      if (e.target.selectionStart === 0 && e.key === '0') {
        e.preventDefault();
      }
    } else if (e.key === '0') {
      e.preventDefault();
    }
  }
  removeLeadingZeros(value: any) {
    value.srcElement.value = value.srcElement.value.replace(/^0+/, '');
  }
  keyPressAlphaNumeric(event) {
    if (this.config.countryName === 'Tanzania') {
      const inp = String.fromCharCode(event.keyCode);

      if (/[a-zA-Z0-9]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    } else {
      const inp = String.fromCharCode(event.keyCode);
      if (/[0-9]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
  }

  private getCountryCodes() {
    this.bankingMetadataService.getCountryCodes().subscribe((response) => {
      this.countryCodes = response.countryCodes;
    });
  }

  private getBanksList() {
    this.bankingMetadataService.getBanksList('RTG').subscribe((response) => {
      this.banks = response.body.banks;

      let bankName: any;
      if (
        !this.paymentModel.selectedBank &&
        this.editReviewModel &&
        this.editReviewModel.route &&
        this.editReviewModel.route.branch.bank &&
        this.editReviewModel.route.branch.bank.name
      ) {
        bankName = this.editReviewModel.route.branch.bank.name;
      }

      if (this.paymentModel.selectedBank && this.paymentModel.selectedBank.name) {
        bankName = this.paymentModel.selectedBank.name;
      }

      if (bankName) {
        const findAccs = this.banks.filter((value) => {
          return value.name === bankName;
        });
        this.paymentModel.selectedBank = findAccs[0];
        this.bankValueChanged(this.paymentModel.selectedBank, false);
      }
    });
  }

  private getBankBranches() {
    this.bankingMetadataService.getBankBranches(this.paymentModel.selectedBank, 'RTG').subscribe((response) => {
      this.paymentModel.branches = response.body.banks[0].branches;

      if (this.editSelectedBranch) {
        const findAccs = this.paymentModel.branches.filter((value) => {
          return value.name === this.editSelectedBranch;
        });
        this.paymentModel.selectedBranch = findAccs[0];
        this.branchDropDownChanged(this.paymentModel.selectedBranch);
      }
    });
  }

}
