import { createAction, props } from '@ngrx/store';

export const setSelectedRecipientData = createAction(
  '[ipay Component] setSelectedRecipientData',
  props<{ selectedRecipientData: any }>()
);

export const setSelectedIpayBeneficiaryData = createAction(
  '[ipay Component] Set Selected Ipay Beneficiary Data',
  props<{ selectedIpayBeneficiaryData: any }>()
);

export const setPaymentType = createAction(
  '[ipay Component] Set payment type',
   props<{ paymentType: string }>()
);

export const TearDownBridge = createAction(
  '[ipay Component] Tear Down Bridge',
   props<{ isError: boolean }>()
);