import { createReducer, on } from '@ngrx/store';
import { InternationalPaymentState } from '../state/ipay.state';
import * as ipayActions from '../actions/ipay.action';

const selectedRecipientInitState = {};
const selectedIpayBeneficiaryInitState = {};
const paymentTypeInitState = '';
const errorInitState = false;

const selectedIpayData: InternationalPaymentState = {
  selectedRecipientData: selectedRecipientInitState,
  selectedIpayBeneficiary: selectedIpayBeneficiaryInitState,
  paymentType: paymentTypeInitState,
  isError: errorInitState
};

export const ipayReducer = createReducer(
  selectedIpayData,
  on(ipayActions.setSelectedRecipientData, (state, { selectedRecipientData }) => ({
    ...state,
    selectedRecipientData,
  })),
  on(ipayActions.setSelectedIpayBeneficiaryData, (state, { selectedIpayBeneficiaryData }) => ({
    ...state,
    selectedIpayBeneficiaryData,
  })),
  on(ipayActions.setPaymentType, (state, { paymentType }) => ({
    ...state,
    paymentType,
  })),
  on(ipayActions.TearDownBridge, (state, { isError }) => ({
    ...state,
    isError,
  }))
);
